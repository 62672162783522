import { Route, Switch } from "react-router-dom";
import Dashboard from "./components/pages/Dashboard";
import React, { Component } from "react";
import Login from "./components/auth/Login";
import NotFound from "./components/layout/NotFound";
import PrivateRoute from "./components/private-route/PrivateRoute";
import Register from "./components/auth/Register";
import { connect } from "react-redux";
import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import "../node_modules/font-awesome/css/font-awesome.css";
import "jquery/dist/jquery.min";
import "popper.js/dist/popper";

import OrderList from "./components/pages/OrderList";
import ViewCart from "./components/pages/ViewCart";
import Navbar from "./components/partials/Navbar";
import Sidebar from "./components/partials/Sidebar";
import UserSettings from "./components/partials/UserSettings";
import Footer from "./components/partials/Footer";
import { setGroups } from "./actions/authActions";
import { setmultistoreData } from "./actions/authActions";
import queryString from "query-string";
import { logoutUser } from "./actions/authActions";
import IdleTimer from "react-idle-timer";
import "react-confirm-alert/src/react-confirm-alert.css";
import Tabula from "./components/welcome/Tabula";
import support from "./components/pages/support";
import AnimalDrug from "./components/pages/AnimalDrug";
class RouteData extends Component {
  componentDidMount() {
    if (this.props.records === undefined && this.props.auth.user.superadmin) {
      this.props.setGroups();
    }
    if (
      this.props.auth.user.multistore &&
      this.props.multistoreData.length === 0
    ) {
      this.props.setmultistoreData(this.props.auth.user.email);
    }
    if (
      queryString.parse(window.location.search).reference &&
      queryString.parse(window.location.search).reference.includes("Bearer")
    ) {
      localStorage.setItem(
        "jwtToken",
        queryString.parse(window.location.search).reference
      );
      window.location.href = "/dashboard";
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.multistoreData.length !== prevProps.multistoreData.length) {
      this.props.setmultistoreData(this.props.auth.user.email);
    }
  }

  onLogoutClick = (e) => {
    this.props.logoutUser(this.props.history);
  };

  onIdle = (e) => {
    this.onLogoutClick();
  };

  render() {
    return (
      <div className="App">
        <IdleTimer onIdle={this.onIdle} debounce={250} timeout={21600000} />
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/login" component={Login} />

          <div
            className="container-fluid"
            style={{ minHeight: "calc(-120px + 105vh)" }}
          >
            <div className="row">
              <Sidebar />
              <Tabula />
              <div className="col" id="main">
                <Navbar />
                <Switch>
                  <Switch>
                    <PrivateRoute
                      exact
                      path="/dashboard"
                      component={Dashboard}
                    />
                    <PrivateRoute
                      exact
                      path="/order-list"
                      component={OrderList}
                    />
                    <PrivateRoute
                      exact
                      path="/animal-med"
                      component={AnimalDrug}
                    />
                    <PrivateRoute
                      exact
                      path="/view-cart"
                      component={ViewCart}
                    />

                    <PrivateRoute
                      exact
                      path="/user-settings"
                      component={UserSettings}
                    />
                    <PrivateRoute exact path="/support" component={support} />
                    {this.props.auth.user.superadmin && (
                      <>
                        <PrivateRoute
                          exact
                          path="/dashboard"
                          component={OrderList}
                        />
                      </>
                    )}
                  </Switch>
                  <Route exact path="*" component={NotFound} />
                </Switch>
              </div>
            </div>
          </div>
        </Switch>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  groups: state.groups,
  multistoreData: state.multistoreData,
});

export default connect(mapStateToProps, {
  setGroups,
  logoutUser,
  setmultistoreData,
})(RouteData);
