import React from 'react'
import classnames from "classnames";
import axios from "axios";
import { toast } from 'react-toastify';
import $ from 'jquery';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import isEmpty from 'is-empty';



import 'react-toastify/dist/ReactToastify.css';

class AnimalDrugAdd extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ndc: "",
            proprietary_name: "",
            enumeration_date: "",
            non_proprietary_name: "",
            labeler: "",
            product_type: "",
            errors: {}
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.user !== undefined
            && nextProps.auth.user.data !== undefined
            && nextProps.auth.user.data.message !== undefined) {
            $('#add-animaldrug-modal').modal('hide');
            toast(nextProps.auth.user.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }
    // setFromDate = (date) => {
    //     this.setState({ enumeration_date: date });
    // }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onAnimalDrugAdd = e => {
        e.preventDefault();
        let { errors } = this.state
        if (this.state.ndc === '') {
            errors.ndc = "This field is required"
        } else {
            delete errors.ndc
        }
        if (this.state.proprietary_name === '') {
            errors.proprietary_name = "This field is required"
        } else {
            delete errors.proprietary_name
        }
        // if(this.state.enumeration_date===''){
        //     errors.enumeration_date = "This field is required"
        // }else{
        //     delete errors.enumeration_date
        // }
        if (this.state.non_proprietary_name === '') {
            errors.non_proprietary_name = "This field is required"
        } else {
            delete errors.non_proprietary_name
        }
        if (this.state.labeler === '') {
            errors.labeler = "This field is required"
        } else {
            delete errors.labeler
        }
        if (this.state.product_type === '') {
            errors.product_type = "This field is required"
        } else {
            delete errors.product_type
        }
        if (isEmpty(errors)) {
            const newDoctor = {
                // enumeration_date: (this.state.enumeration_date),
                // updated_date: new Date(this.state.updated_date),
                ndc: this.state.ndc,
                proprietary_name: this.state.proprietary_name,
                non_proprietary_name: this.state.non_proprietary_name,
                labeler: this.state.labeler,
                product_type: this.state.product_type,
            };
            axios.post('/api/animaldrug-add', newDoctor)
                .then((response) => {
                    this.props.getData();
                    $('#add-animaldrug-modal').modal('hide');
                }, (error) => {
                    console.log(error);
                });
        } else {
            this.setState({ errors })
        }

        //this.props.addUser(newUser, this.props.history);
    };
    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="add-animaldrug-modal" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add Animal Drug</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onAnimalDrugAdd} id="add-animaldrug">
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="ndc">NDC</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.ndc}
                                                id="ndc"
                                                type="text"
                                                error={errors.ndc}
                                                className={classnames("form-control", {
                                                    invalid: errors.ndc
                                                })} />
                                            <span className="text-danger">{errors.ndc}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="proprietary_name">Proprietary Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.proprietary_name}
                                                type="text"
                                                id="proprietary_name"
                                                className={classnames("form-control", {
                                                    invalid: errors.proprietary_name
                                                })}
                                            />
                                            <span className="text-danger">{errors.proprietary_name}</span>
                                        </div>
                                    </div>
                                    {/* <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="non_proprietary_name">Enumeration Date</label>
                                        </div>
                                        <div className="col-md-9">
                                            {/* <div class="form-group col-md-9"> */}


                                    {/* <DatePicker selected={this.state.enumeration_date} onChange={(date) => this.setFromDate(date)} className="form-control" /> */}
                                    {/* </div> */}
                                    {/* <span className="text-danger">{errors.enumeration_date}</span>
                                        </div> */}
                                    {/* </div> */}
                                    {/* <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="updated_date">Updated Date</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.updated_date}
                                                error={errors.updated_date}
                                                id="updated_date"
                                                type="datetime-local"
                                                className={classnames("form-control", {
                                                    invalid: errors.updated_date
                                                })}
                                            />
                                            <span className="text-danger">{errors.updated_date}</span>
                                        </div>
                                    </div> */}
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="non_proprietary_name">Non Proprietary Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.non_proprietary_name}
                                                error={errors.non_proprietary_name}
                                                id="non_proprietary_name"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.non_proprietary_name
                                                })}
                                            />
                                            <span className="text-danger">{errors.non_proprietary_name}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="labeler">Labeler</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                // autoComplete={''}
                                                onChange={this.onChange}
                                                value={this.state.labeler}
                                                id="labeler"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.labeler
                                                })}
                                            />
                                            <span className="text-danger">{errors.labeler}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="product_type">Product Type</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.product_type}
                                                id="product_type"
                                                type="text"
                                                error={errors.product_type}
                                                className={classnames("form-control", {
                                                    invalid: errors.product_type
                                                })} />
                                            <span className="text-danger">{errors.product_type}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="add-animaldrug"
                                    type="submit"
                                    // data-toggle="modal" data-target="#add-doctor-modal"
                                    className="btn btn-primary">
                                    Add Animal Drug
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default AnimalDrugAdd;
