import moment from "moment";
import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <div className="my-1">
        <footer className="text-center text-lg-start text-white bg-primary">
          <div className="container p-4 pb-0">
            <section className="p-3 pt-0">
              <div className="row d-flex align-items-center">
                <div className="col-md-12 col-lg-12 text-center text-md-start">
                  <div className="p-3">
                    Copyright {moment().year()}
                    <a className="text-white" href="https://rxconnexion.com/">
                      {" "}
                      PSGA, Inc
                    </a>
                  </div>
                  <div className="">
                    <a
                      className="text-white"
                      href="https://www.rxconnexion.com/privacy"
                    >
                      {" "}
                      Privacy Policy
                    </a>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
