import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import {
  GET_ERRORS,
  SET_CURRENT_USER,
  USER_LOADING,
  SET_PHARMACY,
  SET_GROUPS,
  SET_MULTISTOREDATA,
} from "./types";

export const registerUser = (userData, history) => (dispatch) => {
  axios
    .post("/api/register", userData)
    .then((res) => history.push("/login"))
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const loginUser = (userData) => (dispatch) => {
  axios
    .post("/api/login", userData)
    .then((res) => {
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      setAuthToken(token);
      const decoded = jwt_decode(token);
      dispatch(setCurrentUser(decoded));
      dispatch(setGroups());
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

export const setUserLoading = () => {
  return {
    type: USER_LOADING,
  };
};

export const setPharmacies = (pharmacy) => (dispatch) => {
  dispatch({
    type: SET_PHARMACY,
    payload: pharmacy,
  });
};

export const setGroups = () => (dispatch) => {
  axios
    .post("/api/group-data")
    .then((res) => {
      if (res.data.records !== undefined) {
        dispatch({
          type: SET_GROUPS,
          payload: res.data.records,
        });
      }
    })
    .catch((error) => {
      // dispatch({
      //     type: GET_ERRORS,
      //     payload: "Error"
      // })
      if (error.response) {
        if (error.response.status === 403) {
          window.location.href = "./login";
        }
      }
    });
};

export const setmultistoreData = (email) => (dispatch) => {
  axios
    .post("/api/multistore-data", { email: email })
    .then((res) => {
      if (res.data.records !== undefined) {
        dispatch({
          type: SET_MULTISTOREDATA,
          payload: res.data.records,
        });
      }
    })
    .catch((error) => {
      // dispatch({
      //     type: GET_ERRORS,
      //     payload: "Error"
      // })
      if (error.response) {
        if (error.response.status === 403) {
          window.location.href = "./login";
        }
      }
    });
};

export const logoutUser = (history) => (dispatch) => {
  localStorage.removeItem("jwtToken");
  setAuthToken(false);
  dispatch(setCurrentUser({}));
  // history.push("/login")
  window.location.href = "https://tabula.psgarx.com?logout=true";
};

export const refreshUser = (userData, type) => (dispatch) => {
  axios
    .post("/api/refreshuser", userData)
    .then((res) => {
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      setAuthToken(token);
      const decoded = jwt_decode(token);
      dispatch(setCurrentUser(decoded));
      if (type === "tabula") {
        window.location.href =
          "https://tabula.psgarx.com?reference=" +
          localStorage.getItem("jwtToken");
      } else {
        window.location.href =
          "https://doorstep.psgarx.com?reference=" +
          localStorage.getItem("jwtToken");
      }
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
