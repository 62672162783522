import { SET_MULTISTOREDATA } from "../actions/types";
const initialState = [];

export default function(state = initialState, action) {
    switch (action.type) {
        case SET_MULTISTOREDATA:
            return action.payload;
        default:
            return state;
    }
}
