import React, { Component, Fragment } from "react";
import ReactDatatable from "../../utils/ReactDatatable";
import { connect } from "react-redux";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import { logoutUser } from "../../actions/authActions";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { confirmAlert } from "react-confirm-alert";
class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: "name",
        text: "Name",
        className: "name",
        align: "left",
        sortable: true,
        showDescription: true,
      },
      {
        key: "case_quantity",
        text: "Case Quantity",
        className: "case_quantity",
        align: "left",
        sortable: true,
      },
      {
        key: "rx",
        text: "Item Code",
        className: "rx",
        align: "left",
        sortable: true,
      },
      {
        key: "price",
        text: "Unit Price",
        className: "price",
        align: "left",
        sortable: true,
      },
      {
        key: "category",
        text: "Category",
        className: "category",
        align: "left",
        sortable: true,
      },
    ];
    if (
      !this.props.auth.user.superadmin &&
      this.props.auth.user.final_user !==
        "david.bigoness@animalmedexpress.com" &&
      this.props.auth.user.final_user !== "cortney@animalmedexpress.com"
    ) {
      this.columns.push({
        key: "quantity",
        text: "Quantity",
        className: "quantity",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              <input
                min={0}
                onChange={(e) => (record.quantity = e.target.value)}
                type="number"
                className="form-control"
              />
            </Fragment>
          );
          // });
        },
      });
      this.columns.push({
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => this.addCart(record)}
                style={{ marginRight: "5px" }}
              >
                <FontAwesomeIcon icon={faPlus} /> ADD TO CART
              </button>
            </Fragment>
          );
        },
      });
    }

    this.config = {
      page_size: 20,
      length_menu: [20, 50, 100, 500],
      filename: "Log List",
      no_data_text: "No Log found!",
      button: {
        excel: false,
        viewcart: true,
      },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: false,
      show_pagination: true,
      show_info: true,
      sort: {
        column: "name",
        order: "asc",
      },
    };

    this.state = {
      records: [],
      totalCount: 0,
      currentRecord: {},
      filterData: {
        page_number: 1,
        page_size: 20,
        sort_order: { column: "name", order: "asc" },
      },

      name: "",
      rx: "",
      category: "",
      quantity: "",
    };
  }

  componentDidMount() {
    this.resetSearch();
  }
  addCart(record) {
    if (
      record.quantity === "" ||
      record.quantity === undefined ||
      record.quantity === "0"
    ) {
      alert("Please Select Quantity");
    } else {
      const newCard = {
        email: this.props.auth.user.final_user,
        record: record,
      };
      console.log(newCard);
      axios.post("/api/quantity-add", newCard).then(
        (response) => {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          // record.quantity = "";
        },

        (error) => {
          console.log(error);
        }
      );
    }
  }

  getData() {
    this.setState({ loading: true });
    let filterData = this.state.filterData;
    filterData.search = {
      ...(this.state.rx !== "" && { rx: this.state.rx }),
      ...(this.state.category !== "" && { category: this.state.category }),
      ...(this.state.name !== "" && { name: this.state.name }),
    };
    axios
      .post("/api/animal-med", filterData)
      .then((res) => {
        var result = [];
        // add data rows in sheet array
        for (let i in res.data.records) {
          let each = res.data.records[i];
          if (each.price !== undefined) {
            each.price = "$" + each.price;
          }
          if (each.category === "otc") {
            each.category = "OTC";
          }
          if (each.category === "prescription") {
            each.category = "Prescription";
          }
          if (each.category === "nutritional") {
            each.category = "Nutritional";
          }
          each.case_quantity = each.quantity;
          each.quantity = "";
          result.push(each);
        }
        this.setState({
          records: result,
          totalCount: res.data.count,
          loading: false,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  resetSearch = () => {
    this.setState(
      {
        rx: "",
        name: "",
        category: "",
        filterData: {
          sort_order: { column: "name", order: "asc" },
        },
      },
      () => this.getData()
    );
  };

  onChangeSelect = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  onSearch = (e) => {
    e.preventDefault();
    this.getData();
  };

  showDescription = (rx) => {
    confirmAlert({
      title: "Description",
      message: rx,
      buttons: [
        {
          label: "Ok",
          onClick: () => {},
        },
      ],
    });
  };

  render() {
    return (
      <>
        <div className="d-flex" id="wrapper">
          {this.state.loading && (
            <div className="loading-wrapper">
              <PulseLoader color="#007bff" loading={true} size={20} />
            </div>
          )}

          <div id="page-content-wrapper">
            <div className="container-fluid">
              <h1 className="mt-2 text-primary" style={{ display: "inline" }}>
                AnimalMedRx
              </h1>
              <h4 className="float-right" style={{ marginTop: "10px" }}>
                <span style={{ color: "red" }}>
                  For inquiries regarding orders, please call: 615-538-1424
                </span>
              </h4>
              <br></br>
              <br></br>
              <form noValidate onSubmit={this.onSearch}>
                <div className="form-row">
                  <div className="form-group col-md-3">
                    <label htmlFor="rx">Item Code</label>
                    <input
                      onChange={this.onChange}
                      value={this.state.rx}
                      id="rx"
                      type="text"
                      className="form-control"
                    />
                  </div>

                  <div className="form-group col-md-3">
                    <label htmlFor="name">Name</label>
                    <input
                      onChange={this.onChange}
                      value={this.state.name}
                      id="name"
                      type="text"
                      className="form-control"
                    />
                  </div>

                  <div className="form-group col-md-3">
                    <label htmlFor="category">Category</label>
                    <select
                      onChange={this.onChangeSelect}
                      id="category"
                      className="form-control"
                    >
                      <option value="">ALL</option>
                      <option value="prescription">Prescription</option>
                      <option value="otc">OTC</option>
                      <option value="nutritional">Nutritional</option>
                    </select>
                  </div>
                </div>
                <div className="form-group col-md-3">
                  <button type="submit" className="btn btn-primary">
                    Search
                  </button>
                  <button
                    type="reset"
                    onClick={this.resetSearch}
                    className="btn btn-secordry"
                  >
                    Reset
                  </button>
                </div>
              </form>

              <ReactDatatable
                config={this.config}
                records={this.state.records}
                columns={this.columns}
                dynamic={false}
                total_record={this.state.totalCount}
                loading={true}
                showDescription={this.showDescription}
              />
            </div>
          </div>
        </div>

        <ToastContainer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  multistoreData: state.multistoreData,
});

export default connect(mapStateToProps, { logoutUser })(Dashboard);
