import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { registerUser } from "../../actions/authActions";
import classnames from "classnames";
import isEmpty from 'is-empty';
import "react-datepicker/dist/react-datepicker.css";
import bona from "../../utils/psga.png";


class Register extends Component {

    constructor() {
        super();
        this.state = {
            name: "",
            email: "",
            password: "",
            password2: "",
            rx_bin: "",
            rx_group: "",
            upline: "",
            assigned_date: "",
            first_name: "",
            last_name: "",
            street: "",
            city: "",
            state: "",
            zip: "",
            website: "",
            phone: "",
            fax: "",
            npi: "",
            software: "",
            psao: "",
            NABP: "",
            business_email: "",
            phone1: "",
            addressline_2: "",
            errors: {}
        };
    }

    componentDidMount() {
        if (this.props.auth.isAuthenticated) {
            this.props.history.push("/dashboard");
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };
    setFromDate = (date) => {
        this.setState({ assigned_date: date });
    }

    onChangeSelect = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }


    onSubmit = e => {
        e.preventDefault();
        let { errors } = this.state
        if (this.state.name === '') {
            errors.name = "This field is required"
        } else {
            delete errors.name
        }
        if (this.state.email === '') {
            errors.email = "This field is required"
        } else {
            delete errors.email
        }
        if (this.state.npi === '') {
            errors.npi = "This field is required"
        }
        else if (this.state.npi.length !== 10) {
            errors.npi = "Invalid NPI (10 Digits)"
        } else {
            delete errors.npi
        }
        if (this.state.password === '') {
            errors.password = "This field is required"
        } else {
            delete errors.password
        }
        if (this.state.software === '') {
            errors.software = "This field is required"
        } else {
            delete errors.software
        }
        if (this.state.first_name === '') {
            errors.first_name = "This field is required"
        } else {
            delete errors.first_name
        }
        if (this.state.last_name === '') {
            errors.last_name = "This field is required"
        } else {
            delete errors.last_name
        }
        if (this.state.phone1 === '') {
            errors.phone1 = "This field is required"
        } else {
            delete errors.phone1
        }
        if (this.state.phone === '') {
            errors.phone = "This field is required"
        } else {
            delete errors.phone
        }
        if (this.state.city === '') {
            errors.city = "This field is required"
        } else {
            delete errors.city
        } if (this.state.state === '') {
            errors.state = "This field is required"
        } else {
            delete errors.state
        }
        if (this.state.zip === '') {
            errors.zip = "This field is required"
        } else {
            delete errors.zip
        }


        if (this.state.password2 === '') {
            errors.password2 = "This field is required"
        } else if (this.state.password !== this.state.password2) {
            errors.password2 = "Password not match"
        } else {
            delete errors.password2
        }
        if(this.state.NABP === ''){
            errors.NABP = "This field is required"
        }
        else if(this.state.NABP.length !== 7){
            errors.NABP = "Invalid NABP (7 Digits)"
        }
        else{
            delete errors.NABP
        }
        if (isEmpty(errors)) {
            const newUser = {
                name: this.state.name,
                email: this.state.email,
                password: this.state.password,
                password2: this.state.password2,
                rx_bin: this.state.rx_bin,
                rx_group: this.state.rx_group,
                upline: this.state.upline,
                assigned_date: this.state.assigned_date,
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                street: this.state.street,
                city: this.state.city,
                state: this.state.state,
                zip: this.state.zip,
                website: this.state.website,
                phone: this.state.phone,
                fax: this.state.fax,
                npi: this.state.npi,
                software: this.state.software,
                psao: this.state.psao,
                NABP: this.state.NABP,
                phone1: this.state.phone1,
                business_email: this.state.business_email,
                addressline_2: this.state.addressline_2

            };

            this.props.registerUser(newUser, this.props.history);
        } else {
            this.setState({
                errors
            })
        }
    };

    render() {
        const { errors } = this.state;
        return (
            <div className="container">
                <div className="row mt-6">
                    <div className="col-md-9 mx-auto mt-5 card shadow-lg">
                        <div className="card-body p-1">
                            {/* <Link to="/" className="btn-flat waves-effect">
                            <i className="material-icons left">keyboard_backspace</i> Back to
                            home
                        </Link> */}
                            {/* <div className="col s12" style={{ paddingLeft: "11.250px" }}> */}
                            <h2 className="text-left text-primary mt-3 " style={{ marginBottom: "20px" }}><img src={bona} height={50} /></h2>

                            <h2 className="text-left text-primary mt-3">Business Detail</h2>                            {/* <p className="grey-text text-darken-1">
                                Already have an account? <Link to="/login">Log in</Link>
                            </p> */}
                            {/* </div> */}
                            <form noValidate onSubmit={this.onSubmit}>
                                {/* <div className="input-field col s12"> */}
                                <div className="row mt-12">

                                    <div className="col-md-6 col-12">
                                        <label htmlFor="name">Pharmacy Name</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.name}
                                            id="name"
                                            type="text"
                                            required
                                            error={errors.name}
                                            className={classnames("form-control", {
                                                invalid: errors.name
                                            })} />
                                        <span className="text-danger">{errors.name}</span>
                                    </div>



                                    <div className="col-md-6 col-12">
                                        <label htmlFor="business_email">Pharmacy Email</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.business_email}
                                            error={errors.business_email}
                                            id="business_email"
                                            type="email"
                                            required
                                            className={classnames("form-control", {
                                                invalid: errors.business_email
                                            })}
                                        />
                                        <span className="text-danger">{errors.business_email}</span>
                                    </div>
                                </div>
                                {/* <div className="row mt-12">
                                    <div className="col-md-6 col-12">
                                        <label htmlFor="password">Password</label>
                                        <input
                                            autoComplete={''}
                                            onChange={this.onChange}
                                            value={this.state.password}
                                            error={errors.password}
                                            id="password"
                                            type="password"
                                            className={classnames("form-control", {
                                                invalid: errors.password
                                            })}
                                        />
                                        <span className="text-danger">{errors.password}</span>
                                    </div>

                                    <div className="col-md-6 col-12">
                                        <label htmlFor="password2">Confirm Password</label>
                                        <input
                                            autoComplete={''}
                                            onChange={this.onChange}
                                            value={this.state.password2}
                                            id="password2"
                                            type="password"
                                            className={classnames("form-control", {
                                                invalid: errors.password2
                                            })}
                                        />
                                        <span className="text-danger">{errors.password2}</span>
                                    </div>
                                </div> */}
                                <div className="row mt-12">
                                    <div className="col-md-6 col-12">
                                        <label htmlFor="name">Pharmacy NPI</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.npi}
                                            id="npi"
                                            type="text"
                                            error={errors.npi}
                                            className={classnames("form-control", {
                                                invalid: errors.npi
                                            })} />
                                        <span className="text-danger">{errors.npi}</span>
                                    </div>



                                    <div className="col-md-6 col-12">
                                        <label htmlFor="software">Pharmacy Software</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.software}
                                            id="software"
                                            type="text"
                                            error={errors.software}
                                            className={classnames("form-control", {
                                                invalid: errors.software
                                            })} />
                                        <span className="text-danger">{errors.software}</span>
                                    </div>
                                </div>




                                <div className="row mt-12">
                                    <div className="col-md-6 col-12">
                                        <label htmlFor="psao">PSAO</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.psao}
                                            id="psao"
                                            type="text"
                                            error={errors.psao}
                                            className={classnames("form-control", {
                                                invalid: errors.psao
                                            })} />
                                        <span className="text-danger">{errors.psao}</span>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <label htmlFor="NABP">NABP #</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.NABP}
                                            id="NABP"
                                            type="text"
                                            error={errors.NABP}
                                            className={classnames("form-control", {
                                                invalid: errors.NABP
                                            })} />
                                        <span className="text-danger">{errors.NABP}</span>
                                    </div>
                                </div>
                                <div className="row mt-12">


                                    {/* <div className="col-md-6 col-12">
                                        <label htmlFor="assigned_date">Assigned Date</label>
                                        <DatePicker selected={this.state.assigned_date} onChange={(date) => this.setFromDate(date)} className="form-control" />
                                        <span className="text-danger">{errors.assigned_date}</span>
                                    </div> */}
                                    <div className="col-md-6 col-12">
                                        <label htmlFor="street">Address Line 1</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.street}
                                            id="street"
                                            type="text"
                                            error={errors.street}
                                            className={classnames("form-control", {
                                                invalid: errors.street
                                            })} />
                                        <span className="text-danger">{errors.street}</span>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <label htmlFor="addressline_2">Address Line 2</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.addressline_2}
                                            id="addressline_2"
                                            type="text"
                                            error={errors.addressline_2}
                                            className={classnames("form-control", {
                                                invalid: errors.addressline_2
                                            })} />
                                        <span className="text-danger">{errors.addressline_2}</span>
                                    </div>
                                </div>
                                <div className="row mt-12">

                                    <div className="col-md-6 col-12">
                                        <label htmlFor="city">City</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.city}
                                            id="city"
                                            type="text"
                                            error={errors.city}
                                            className={classnames("form-control", {
                                                invalid: errors.city
                                            })} />
                                        <span className="text-danger">{errors.city}</span>
                                    </div>

                                    <div className="col-md-6 col-12">
                                        <label htmlFor="state">State</label>
                                        <select name="state" onClick={this.onChange} className="form-control" id="state">
                                            <option value="" selected="">Please Select</option>
                                            <option value="AL">Alabama</option>
                                            <option value="AK">Alaska</option>
                                            <option value="AS">American Samoa</option>
                                            <option value="AZ">Arizona</option>
                                            <option value="AR">Arkansas</option>
                                            <option value="AA">Armed Forces America</option>
                                            <option value="AE">Armed Forces Europe /Canada / Middle East / Africa</option>
                                            <option value="AP">Armed Forces Pacific</option>
                                            <option value="CA">California</option>
                                            <option value="CO">Colorado</option>
                                            <option value="CT">Connecticut</option>
                                            <option value="DE">Delaware</option>
                                            <option value="DC">District of Columbia</option>
                                            <option value="FM">Federated States of Micronesia</option>
                                            <option value="FL">Florida</option>
                                            <option value="GA">Georgia</option>
                                            <option value="GU">Guam</option>
                                            <option value="HI">Hawaii</option>
                                            <option value="ID">Idaho</option>
                                            <option value="IL">Illinois</option>
                                            <option value="IN">Indiana</option>
                                            <option value="IA">Iowa</option>
                                            <option value="KS">Kansas</option>
                                            <option value="KY">Kentucky</option>
                                            <option value="LA">Louisiana</option>
                                            <option value="ME">Maine</option>
                                            <option value="MP">Mariana Islands, Northern</option>
                                            <option value="MH">Marshall Islands</option>
                                            <option value="MD">Maryland</option>
                                            <option value="MA">Massachusetts</option>
                                            <option value="MI">Michigan</option>
                                            <option value="MN">Minnesota</option>
                                            <option value="MS">Mississippi</option>
                                            <option value="MO">Missouri</option>
                                            <option value="MT">Montana</option>
                                            <option value="NE">Nebraska</option>
                                            <option value="NV">Nevada</option>
                                            <option value="NH">New Hampshire</option>
                                            <option value="NJ">New Jersey</option>
                                            <option value="NM">New Mexico</option>
                                            <option value="NY">New York</option>
                                            <option value="NC">North Carolina</option>
                                            <option value="ND">North Dakota</option>
                                            <option value="OH">Ohio</option>
                                            <option value="OK">Oklahoma</option>
                                            <option value="OR">Oregon</option>
                                            <option value="PA">Pennsylvania</option>
                                            <option value="PR">Puerto Rico</option>
                                            <option value="RI">Rhode Island</option>
                                            <option value="SC">South Carolina</option>
                                            <option value="SD">South Dakota</option>
                                            <option value="TN">Tennessee</option>
                                            <option value="TX">Texas</option>
                                            <option value="UT">Utah</option>
                                            <option value="VT">Vermont</option>
                                            <option value="VI">Virgin islands</option>
                                            <option value="VA">Virginia</option>
                                            <option value="WA">Washington</option>
                                            <option value="WV">West Virginia</option>
                                            <option value="WI">Wisconsin</option>
                                            <option value="WY">Wyoming</option>

                                        </select>
                                    </div>
                                </div>
                                <div className="row mt-12">

                                    <div className="col-md-6 col-12">
                                        <label htmsuperadminlFor="zip">Zip</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.zip}
                                            id="zip"
                                            type="text"
                                            error={errors.zip}
                                            className={classnames("form-control", {
                                                invalid: errors.zip
                                            })} />
                                        <span className="text-danger">{errors.zip}</span>
                                    </div>


                                    <div className="col-md-6 col-12">
                                        <label htmlFor="website">Website</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.website}
                                            id="website"
                                            type="text"
                                            error={errors.website}
                                            className={classnames("form-control", {
                                                invalid: errors.website
                                            })} />
                                        <span className="text-danger">{errors.website}</span>
                                    </div>
                                </div>
                                <div className="row mt-12">

                                    <div className="col-md-6 col-12">
                                        <label htmlFor="phone">Phone</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.phone}
                                            id="phone"
                                            type="text"
                                            error={errors.phone}
                                            className={classnames("form-control", {
                                                invalid: errors.phone
                                            })} />
                                        <span className="text-danger">{errors.phone}</span>
                                    </div>


                                    <div className="col-md-6 col-12">
                                        <label htmlFor="fax">Fax</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.fax}
                                            id="fax"
                                            type="text"
                                            error={errors.fax}
                                            className={classnames("form-control", {
                                                invalid: errors.fax
                                            })} />
                                        <span className="text-danger">{errors.fax}</span>
                                    </div>
                                </div>
                                <h2 className="text-left text-primary mt-3">Personal Detail</h2>
                                <div className="row mt-12">
                                    <div className="col-md-6 col-12">
                                        <label htmlFor="first_name">First Name</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.first_name}
                                            id="first_name"
                                            type="text"
                                            error={errors.first_name}
                                            className={classnames("form-control", {
                                                invalid: errors.first_name
                                            })} />
                                        <span className="text-danger">{errors.first_name}</span>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <label htmlFor="last_name">Last Name</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.last_name}
                                            id="last_name"
                                            type="text"
                                            error={errors.last_name}
                                            className={classnames("form-control", {
                                                invalid: errors.last_name
                                            })} />
                                        <span className="text-danger">{errors.last_name}</span>
                                    </div>

                                </div>
                                <div className="row mt-12">
                                    <div className="col-md-6 col-12">
                                        <label htmlFor="phone1">Phone</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.phone1}
                                            id="phone1"
                                            type="text"
                                            error={errors.phone1}
                                            className={classnames("form-control", {
                                                invalid: errors.phone1
                                            })} />
                                        <span className="text-danger">{errors.phone1}</span>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <label htmlFor="email">Email</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.email}
                                            id="email"
                                            type="text"
                                            error={errors.email}
                                            className={classnames("form-control", {
                                                invalid: errors.email
                                            })} />
                                        <span className="text-danger">{errors.email}</span>
                                    </div>
                                </div>
                                <div className="row mt-12">
                                    <div className="col-md-6 col-12">
                                        <label htmlFor="password">Password</label>
                                        <input
                                            autoComplete={''}
                                            onChange={this.onChange}
                                            value={this.state.password}
                                            error={errors.password}
                                            id="password"
                                            type="password"
                                            className={classnames("form-control", {
                                                invalid: errors.password
                                            })}
                                        />
                                        <span className="text-danger">{errors.password}</span>
                                    </div>

                                    <div className="col-md-6 col-12">
                                        <label htmlFor="password2">Confirm Password</label>
                                        <input
                                            autoComplete={''}
                                            onChange={this.onChange}
                                            value={this.state.password2}
                                            id="password2"
                                            type="password"
                                            className={classnames("form-control", {
                                                invalid: errors.password2
                                            })}
                                        />
                                        <span className="text-danger">{errors.password2}</span>
                                    </div>
                                </div>
                                <p className="text-center pb-0 mt-2">
                                    <button

                                        type="submit"
                                        className="btn btn-large btn-primary mt-2 px-5">
                                        Register
                                    </button>

                                </p>
                                <h5 className="text-center pb-0 mt-2">Already have an account? <Link to="/login">Log in</Link></h5>

                                {/* </div> */}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { registerUser }
)(withRouter(Register));