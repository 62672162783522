import React from "react";
import PulseLoader from "react-spinners/PulseLoader";
import classnames from "classnames";
import { connect } from "react-redux";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import $ from "jquery";
import { Table } from "react-bootstrap";
import usState from "../../utils/state.json";
import CreditCardInput from "react-credit-card-input";
import isEmpty from "is-empty";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { logoutUser } from "../../actions/authActions";
import "react-toastify/dist/ReactToastify.css";

class ViewCart extends React.Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.state = {
      record: [],
      email: "",
      grandTotal: 0,
      current: "first",
      cardNumber: "",
      expiry: "",
      cvv: "",
      cardError: true,
      loading: false,
      name: "",
      street: "",
      city: "",
      state: "",
      license: "",
      zip: "",
      type: "",
      errors: {},
      state_license: "",
      resale_certificate: "",
      license_no: "",
      shipping_type: "",
      names: "",
      shipping_price: 0,
    };
  }
  componentDidMount() {
    this.getData();
    this.getUserData();
  }
  getData = (e) => {
    const filterData = {
      page_number: 1,
      page_size: 20,
      sort_order: { column: "name", order: "asc" },
    };
    filterData.search = {
      email: this.props.auth.user.email,
    };
    axios
      .post(`/api/viewcart`, filterData)
      .then((res) => {
        let result = [];
        let total = 0;
        let email = "";
        let type = false;

        // add data rows in sheet array
        for (let i in res.data.records) {
          let each = res.data.records[i];
          email = each.email;
          if (each.vendors !== "" || each.vendors !== undefined) {
            for (let j in each.vendors) {
              let eachone = each.vendors[j];
              if (eachone.price !== "") {
                eachone.price = eachone.price.split("$")[1];
              }
              if (
                eachone.shipping === "Premium" &&
                eachone.shipping !== undefined
              ) {
                type = true;
              }
              result.push(eachone);

              total = total + parseFloat(eachone.price * eachone.quantity);
            }
          }
        }
        this.setState({
          record: result,
          email: email,
          type: type,
          grandTotal: total,
          loading: false,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  };
  getUserData = (e) => {
    this.setState({ loading: true });

    const filterData = {
      page_number: 1,
      page_size: 20,
      sort_order: { column: "name", order: "asc" },
    };
    filterData.search = {
      email: this.props.auth.user.final_user,
    };
    axios
      .post(`/api/user-data`, filterData)
      .then((res) => {
        this.setState({
          license_no: res.data.records[0].license_no,
          names: res.data.records[0].name,
          loading: false,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  };
  onLicenseAdd = (e) => {
    let { errors } = this.state;

    e.preventDefault();
    if (this.state.license === "") {
      errors.license = "This field is required";
    } else {
      delete errors.license;
    }
    if (this.state.state_license === "") {
      errors.state_license = "This field is required";
    } else {
      delete errors.state_license;
    }
    if (isEmpty(errors)) {
      const newLicense = {
        license_no: this.state.license,
        state_license: this.state.state_license,
        resale_certificate: this.state.resale_certificate,
        email: this.props.auth.user.final_user,
      };
      axios.post("/api/license-add", newLicense).then(
        (response) => {
          this.getUserData();
          $("#add-license-modal").modal("hide");
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };
  register = (e) => {
    let { errors } = this.state;

    e.preventDefault();
    if (this.state.name === "") {
      errors.name = "This field is required";
    } else {
      delete errors.name;
    }
    if (this.state.street === "") {
      errors.street = "This field is required";
    } else {
      delete errors.street;
    }
    if (this.state.city === "") {
      errors.city = "This field is required";
    } else {
      delete errors.city;
    }
    if (this.state.state === "") {
      errors.state = "This field is required";
    } else {
      delete errors.state;
    }
    if (this.state.zip === "") {
      errors.zip = "This field is required";
    } else {
      delete errors.zip;
    }

    if (this.state.payment_type === "credit_card") {
      if (
        this.state.cardNumber === "" ||
        this.state.cardNumber === undefined ||
        this.state.cardError
      ) {
        errors.cardDetails = "Please fill correct card details";
      } else {
        delete errors.cardDetails;
      }
    } else {
      delete errors.cardNumber;
    }
    if (isEmpty(errors)) {
      this.setState({ loading: true });
      const newUser = {
        email: this.state.email,
        cardNumber: this.state.cardNumber,
        expiry: this.state.expiry,
        cvv: this.state.cvv,
        name: this.state.name,
        street: this.state.street,
        city: this.state.city,
        state: this.state.state,
        zip: this.state.zip,
        grandTotal: this.state.grandTotal,
        record: this.state.record,
        names: this.state.names,
        shipping_price: this.state.shipping_price,
        shipping_type: this.state.shipping_type,
        type: this.state.type,
      };

      axios
        .post("/api/add-credit", newUser)

        .then((res) => {
          this.setState({
            loading: false,
            current: "first",
            cardNumber: "",
            expiry: "",
            cvv: "",
            name: "",
            street: "",
            city: "",
            state: "",
            zip: "",
          });
          if (res.data.success === true) {
            confirmAlert({
              title: "Order Added Successfully",
              message: "Go to Check Your Order.",
              buttons: [
                {
                  label: "OK",
                  onClick: () => this.order(),
                },
              ],
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
            this.setState({
              current: "last",
            });
          }
        })
        .catch((err) => {
          this.setState({
            loading: false,
          });
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } else {
      this.setState({ errors });
    }
  };
  order() {
    window.location.href = "./order-list";
  }
  goBack() {
    this.props.history.goBack();
  }
  onChangeRadio = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.id,
      },
      () => this.calculatePrice()
    );
  };

  calculatePrice = () => {
    let shipping_price =
      this.state.shipping_type === "ups"
        ? 15
        : this.state.shipping_type === "free"
        ? 0
        : this.state.shipping_type === "second_day"
        ? this.state.type
          ? 41
          : 36
        : this.state.shipping_type === "overnight"
        ? this.state.type
          ? 61
          : 57
        : 0;

    this.setState({ shipping_price });
  };
  changeStep = () => {
    if (this.state.grandTotal === 0) {
      alert("Please add product");
    } else if (this.state.shipping_type === "") {
      alert("Please select shipping type");
    } else if (this.state.license_no === undefined) {
      $("#add-license-modal").modal("show");
    } else {
      this.setState({
        current: "last",
      });
    }
  };
  handleCardNumberChange = (e) => {
    this.setState({
      cardNumber: e.target.value,
      cardError: false,
    });
  };

  handleCardExpiryChange = (e) => {
    this.setState({
      expiry: e.target.value,
      cardError: false,
    });
  };
  deleteRecord = (each) => {
    this.setState({ loading: true });

    axios
      .post("/api/cart-delete", {
        rx: each.rx,
      })

      .then((res) => {
        this.getData();
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        this.setState({
          loading: false,
        });
      })

      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  };

  handleCardCVCChange = (e) => {
    this.setState({
      cvv: e.target.value,
      cardError: false,
    });
  };
  setError = () => {
    this.setState({
      cardError: true,
    });
  };
  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { errors } = this.state;
    const iFrame =
      '<div class="AuthorizeNetSeal"> <script type="text/javascript" language="javascript">var ANS_customer_id="8239a4e5-c6ea-4253-b541-aeccd3c2c080";</script> <script type="text/javascript" language="javascript" src="https://verify.authorize.net:443/anetseal/seal.js" ></script> </div>';
    return (
      <>
        {this.state.loading && (
          <div className="loading-wrapper">
            <PulseLoader color="#007bff" loading={true} size={20} />
          </div>
        )}
        <div className="d-flex" id="wrapper">
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <form noValidate onSubmit={this.register}>
                {this.state.current === "first" ? (
                  <div className="col-12">
                    <h1 className="mt-2 text-primary">View Cart</h1>

                    <table className="table table-bordered table-striped">
                      <tbody>
                        <tr>
                          <td
                            style={{
                              padding: "5px",
                              backgroundColor: "#2b3d63 !important",
                              color: "#fff !important",
                              border: "1px solid black",
                            }}
                          >
                            <b>Action</b>
                          </td>
                          <td
                            style={{
                              padding: "5px",
                              backgroundColor: "#2b3d63 !important",
                              color: "#fff !important",
                              border: "1px solid black",
                            }}
                          >
                            <b>QUANTITY</b>
                          </td>
                          <td
                            style={{
                              padding: "5px",
                              backgroundColor: "#2b3d63 !important",
                              color: "#fff !important",
                              border: "1px solid black",
                            }}
                          >
                            <b>DESCRIPTION</b>
                          </td>
                          <td
                            style={{
                              padding: "5px",
                              backgroundColor: "#2b3d63 !important",
                              color: "#fff !important",
                              border: "1px solid black",
                            }}
                          >
                            <b>UNIT PRICE</b>
                          </td>
                          <td
                            style={{
                              padding: "5px",
                              backgroundColor: "#2b3d63 !important",
                              color: "#fff !important",
                              border: "1px solid black",
                            }}
                          >
                            <b>TOTAL</b>
                          </td>
                        </tr>

                        {this.state.record.map((each) => {
                          return (
                            <tr>
                              <td
                                style={{
                                  padding: "5px",
                                  backgroundColor: "#2b3d63 !important",
                                  color: "#fff !important",
                                  border: "1px solid black",
                                }}
                              >
                                <button
                                  className="btn btn-danger btn-sm"
                                  onClick={() => this.deleteRecord(each)}
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </td>
                              <td
                                style={{
                                  padding: "5px",
                                  backgroundColor: "#2b3d63 !important",
                                  color: "#fff !important",
                                  border: "1px solid black",
                                }}
                              >
                                {each.quantity}
                              </td>
                              <td
                                style={{
                                  padding: "5px",
                                  backgroundColor: "#2b3d63 !important",
                                  color: "#fff !important",
                                  border: "1px solid black",
                                }}
                              >
                                {each.name}
                              </td>
                              <td
                                style={{
                                  padding: "5px",
                                  backgroundColor: "#2b3d63 !important",
                                  color: "#fff !important",
                                  border: "1px solid black",
                                }}
                              >
                                ${each.price}
                              </td>
                              <td
                                style={{
                                  padding: "5px",
                                  backgroundColor: "#2b3d63 !important",
                                  color: "#fff !important",
                                  border: "1px solid black",
                                }}
                              >
                                $
                                {parseFloat(each.quantity * each.price).toFixed(
                                  2
                                )}
                              </td>
                            </tr>
                          );
                        })}

                        <tr>
                          <td
                            style={{
                              padding: "5px",
                              textAlign: "center",
                              border: "1px solid",
                            }}
                            colspan="3"
                            rowspan="3"
                          >
                            <i></i>
                          </td>
                        </tr>

                        <tr>
                          <td
                            style={{
                              border: "1px solid",
                              padding: "5px",
                            }}
                          >
                            <b>TOTAL</b>
                          </td>
                          <td
                            style={{
                              border: "1px solid",
                              padding: "5px",
                            }}
                          >
                            <b>
                              ${parseFloat(this.state.grandTotal).toFixed(2)}
                            </b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <br></br>

                    <div className="card">
                      <div className="card-header">
                        <h5 className="mt-2 ">Shipping Detail</h5>
                      </div>
                      <div className="card-body">
                        <div className="row mt-2">
                          <div className="col-md-12">
                            {this.state.grandTotal < 800 &&
                            this.state.type === false ? (
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="shipping_type"
                                  id="ups"
                                  checked={this.state.shipping_type === "ups"}
                                  onChange={this.onChangeRadio}
                                />
                                <label className="form-check-label" for="ups">
                                  Standard Ground (2-7 business days) USPS - $15
                                </label>
                              </div>
                            ) : this.state.type === false ? (
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="shipping_type"
                                  id="free"
                                  checked={this.state.shipping_type === "free"}
                                  onChange={this.onChangeRadio}
                                />
                                <label className="form-check-label" for="free">
                                  Free Shipping for Standard Ground (2-7
                                  business days) USPS.
                                </label>
                              </div>
                            ) : (
                              ""
                            )}
                            <br></br>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="shipping_type"
                                id="second_day"
                                checked={
                                  this.state.shipping_type === "second_day"
                                }
                                onChange={this.onChangeRadio}
                              />
                              <label
                                className="form-check-label"
                                for="second_day"
                              >
                                2nd Business Day -{" "}
                                {this.state.type ? "$41" : "$36"}
                              </label>
                            </div>
                            <br></br>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="shipping_type"
                                id="overnight"
                                checked={
                                  this.state.shipping_type === "overnight"
                                }
                                onChange={this.onChangeRadio}
                              />
                              <label
                                className="form-check-label"
                                for="overnight"
                              >
                                Next Business Day -{" "}
                                {this.state.type ? "$61" : "$57"}
                              </label>
                            </div>
                          </div>

                          <br></br>
                        </div>

                        <br></br>
                      </div>
                      <div className="col-md-6"></div>
                      <br></br>
                      <div className="col-md-6 leftdiv">
                        <table className="table table-bordered table-striped">
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid",
                                  padding: "5px",
                                }}
                              >
                                <b>SubTotal</b>
                              </td>
                              <td
                                style={{
                                  border: "1px solid",
                                  padding: "5px",
                                }}
                              >
                                <b>
                                  $
                                  {parseFloat(this.state.grandTotal).toFixed(2)}
                                </b>
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid",
                                  padding: "5px",
                                }}
                              >
                                <b>Shipping Total</b>
                              </td>
                              <td
                                style={{
                                  border: "1px solid",
                                  padding: "5px",
                                }}
                              >
                                <b>${this.state.shipping_price}</b>
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid",
                                  padding: "5px",
                                }}
                              >
                                <b>GrandTotal</b>
                              </td>
                              <td
                                style={{
                                  border: "1px solid",
                                  padding: "5px",
                                }}
                              >
                                <b>
                                  $
                                  {parseFloat(
                                    this.state.grandTotal +
                                      this.state.shipping_price
                                  ).toFixed(2)}
                                </b>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <p className="text-center pb-0 mt-2">
                      <button
                        type="button"
                        className="btn btn-large btn-outline-primary mt-2 px-5 float-left"
                        onClick={this.goBack}
                      >
                        Back
                      </button>
                    </p>
                    <p className="text-center pb-0 mt-2">
                      <button
                        type="button"
                        className="btn btn-large btn-primary mt-2 px-5 float-right"
                        onClick={this.changeStep}
                      >
                        Proceed to Pay
                      </button>
                    </p>
                  </div>
                ) : (
                  <div className="col-12">
                    <br></br>
                    <div className="card">
                      <div className="card-header">
                        <h2 className="mt-2 text-primary">
                          Payment Information
                        </h2>
                      </div>
                      <div className="card-body">
                        <div className="row mt-2">
                          <div className="col-md-4">
                            <div className="form-check">
                              <label
                                className="form-check-label"
                                for="credit_card"
                              >
                                Credit Card
                              </label>
                            </div>
                            <>
                              <CreditCardInput
                                onError={() => this.setError()}
                                cardNumberInputProps={{
                                  value: this.state.cardNumber,
                                  onChange: this.handleCardNumberChange,
                                  placeHolder: "Enter Card Number",
                                }}
                                cardExpiryInputProps={{
                                  value: this.state.expiry,
                                  onChange: this.handleCardExpiryChange,
                                }}
                                cardCVCInputProps={{
                                  value: this.state.cvv,
                                  onChange: this.handleCardCVCChange,
                                }}
                                fieldClassName="input"
                              />
                              <span
                                className="text-danger"
                                style={{ display: "block" }}
                              >
                                {errors.cardDetails}
                              </span>
                            </>
                          </div>
                          <div className="col-md-6">
                            <iframe
                              style={{
                                height: "95px",
                                borderWidth: 0,
                              }}
                              title={"badge"}
                              srcDoc={iFrame}
                            />
                          </div>
                          <br></br>
                          <br></br>
                          <br></br>
                          <div
                            className="col-md-12"
                            style={{ marginTop: "15px" }}
                          >
                            <h5 htmlFor="name">Billing Address</h5>
                          </div>
                          <div className="col-md-6 col-12">
                            <label htmlFor="name">Name</label>
                            <input
                              onChange={this.onChange}
                              value={this.state.name}
                              id="name"
                              type="text"
                              error={errors.name}
                              className={classnames("form-control", {
                                invalid: errors.name,
                              })}
                            />
                            <span className="text-danger">{errors.name}</span>
                          </div>
                          <div className="col-md-6 col-12">
                            <label htmlFor="street">Address Line 1</label>
                            <input
                              onChange={this.onChange}
                              value={this.state.street}
                              id="street"
                              type="text"
                              error={errors.street}
                              className={classnames("form-control", {
                                invalid: errors.street,
                              })}
                            />
                            <span className="text-danger">{errors.street}</span>
                          </div>

                          <div className="col-md-6 col-12">
                            <label htmlFor="city">City</label>
                            <input
                              onChange={this.onChange}
                              value={this.state.city}
                              id="city"
                              type="text"
                              error={errors.city}
                              className={classnames("form-control", {
                                invalid: errors.city,
                              })}
                            />
                            <span className="text-danger">{errors.city}</span>
                          </div>

                          <div className="col-md-6 col-12">
                            <label htmlFor="state">State</label>
                            <select
                              name="state"
                              onChange={this.onChange}
                              className="form-control"
                              id="state"
                            >
                              <option value="" selected="">
                                Please Select
                              </option>
                              {Object.keys(usState).map((each, i) => (
                                <option
                                  key={i}
                                  value={each}
                                  selected={this.state.billing_state === each}
                                >
                                  {usState[each]}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="col-md-6 col-12">
                            <label htmsuperadminlFor="zip">Zip</label>
                            <input
                              onChange={this.onChange}
                              value={this.state.zip}
                              id="zip"
                              type="text"
                              error={errors.zip}
                              className={classnames("form-control", {
                                invalid: errors.zip,
                              })}
                            />
                            <span className="text-danger">{errors.zip}</span>
                          </div>
                        </div>
                        <p className="text-center pb-0 mt-2">
                          <button
                            type="button"
                            className="btn btn-large btn-primary mt-2 px-5 float-left"
                            onClick={() => this.setState({ current: "first" })}
                          >
                            Back
                          </button>
                          <button
                            type="button"
                            className="btn btn-large btn-primary mt-2 px-5 float-right"
                            onClick={this.register}
                          >
                            Pay
                          </button>
                        </p>
                        <br></br>
                      </div>
                    </div>
                  </div>
                )}
              </form>

              <div>
                <div
                  className="modal fade"
                  id="add-license-modal"
                  data-reset="true"
                >
                  <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-title">Add License Number</h4>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                        >
                          &times;
                        </button>
                      </div>

                      <div className="modal-body">
                        <form
                          noValidate
                          onSubmit={this.onLicenseAdd}
                          id="add-license"
                        >
                          <div className="row mt-2">
                            <div className="col-md-3">
                              <label htmlFor="license">FEIN #</label>
                            </div>
                            <div className="col-md-9">
                              <input
                                onChange={this.onChange}
                                value={this.state.license}
                                id="license"
                                type="text"
                                error={errors.license}
                                className={classnames("form-control", {
                                  invalid: errors.license,
                                })}
                              />
                              <span className="text-danger">
                                {errors.license}
                              </span>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-3">
                              <label htmlFor="state_license">
                                State License #
                              </label>
                            </div>
                            <div className="col-md-9">
                              <input
                                onChange={this.onChange}
                                value={this.state.state_license}
                                id="state_license"
                                type="text"
                                error={errors.state_license}
                                className={classnames("form-control", {
                                  invalid: errors.state_license,
                                })}
                              />
                              <span className="text-danger">
                                {errors.state_license}
                              </span>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-3">
                              <label htmlFor="resale_certificate">
                                Resale Certificate #
                              </label>
                            </div>
                            <div className="col-md-9">
                              <input
                                onChange={this.onChange}
                                value={this.state.resale_certificate}
                                id="resale_certificate"
                                type="text"
                                error={errors.resale_certificate}
                                className={classnames("form-control", {
                                  invalid: errors.resale_certificate,
                                })}
                              />
                              <span className="text-danger">
                                {errors.resale_certificate}
                              </span>
                            </div>
                          </div>
                        </form>
                      </div>

                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                        <button
                          form="add-license"
                          type="submit"
                          // data-toggle="modal" data-target="#add-drug-modal"
                          className="btn btn-primary"
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  records: state.records,
});
export default connect(mapStateToProps, { logoutUser })(ViewCart);
