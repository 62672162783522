import React, { Component } from "react";
import ReactDatatable from "../../utils/ReactDatatable";
import { connect } from "react-redux";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import moment from "moment";
import { logoutUser } from "../../actions/authActions";
import GroupFilter from "./Filter/GroupFilter.js";

import $ from "jquery";

class OrderList extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: "order_id",
        text: "Order ID",
        className: "order_id",
        align: "left",
        sortable: true,
        // view: true
      },
      {
        key: "names",
        text: "Pharmacy Name",
        className: "names",
        align: "left",
        sortable: true,
        // view: true
      },

      {
        key: "grandTotal",
        text: "Grand Total",
        className: "grandTotal",
        align: "left",
        sortable: true,
      },
      {
        key: "date",
        text: "Date",
        className: "date",
        align: "left",
        sortable: true,
      },
    ];
    if (this.props.auth.user.superadmin) {
      this.columns.push({
        key: "email",
        text: "User Email",
        className: "email",
        align: "left",
        sortable: true,
      });
    }
    if (!this.props.auth.user.superadmin || this.props.auth.user.superadmin) {
      this.columns.push({
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <a href="#">
              <span onClick={() => this.showPdf(record)}>PDF</span>
            </a>
          );
        },
      });
    }

    this.config = {
      page_size: 20,
      length_menu: [20, 50, 100],
      filename: "Order List",
      no_data_text: "No record found!",
      button: {
        excel: false,
        print: false,
        csv: true,
      },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: false,
      show_pagination: true,

      show_info: true,
      sort: {
        column: "date",
        order: "asc",
      },
    };

    this.state = {
      records: [],
      totalCount: 0,
      filterData: {
        page_number: 1,
        page_size: 20,
        sort_order: { column: "date", order: "desc" },
      },
      currentRecord: {},
      proprietary_name: undefined,
      rx_group_associated: undefined,

      ndc: undefined,
      non_proprietary_name: undefined,
      labeler: undefined,
      product_type: undefined,
      loading: false,
      isFilterOpen: true,
    };

    this.getData = this.getData.bind(this);
  }
  resetSearch = () => {
    this.setState(
      {
        names: undefined,
        order_id: undefined,
        filterData: {
          page_number: 1,
          page_size: 20,
          sort_order: { column: "_id", order: "desc" },
        },
      },
      () => this.getData()
    );
  };

  componentDidMount() {
    this.getData();
  }

  getData() {
    this.setState({ loading: true });

    let filterData = this.state.filterData;
    filterData.search = {
      ...(this.state.names !== "" && { names: this.state.names }),
      ...(this.state.order_id !== "" && { order_id: this.state.order_id }),
      email: this.props.auth.user.final_user,
    };

    axios
      .post("/api/order-list", filterData)
      .then((res) => {
        let result = [];
        for (let i = 0; i < res.data.records.length; i++) {
          let each = res.data.records[i];
          if (each.date !== "") {
            each.date = moment(each.date).format("L");
          }
          if (each.grandTotal !== "" && each.grandTotal !== undefined) {
            each.grandTotal = (
              parseFloat(each.grandTotal) + parseFloat(each.shipping_price || 0)
            ).toFixed(2);
          }
          result.push(each);
        }
        this.setState({
          records: result,
          totalCount: res.data.count,
          loading: false,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  }
  showPdf = (record) => {
    this.setState({ currentRecord: record }, () => this.getUserData());
  };
  getUserData = (e) => {
    this.setState({ loading: true });

    const filterData = {
      page_number: 1,
      page_size: 20,
      sort_order: { column: "name", order: "asc" },
    };
    filterData.search = {
      email: this.state.currentRecord.email,
    };
    axios
      .post(`/api/user-data`, filterData)
      .then((res) => {
        console.log(res.data.records[0].name);
        this.setState({
          license_no: res.data.records[0].license_no,
          state_license: res.data.records[0].state_license,
          resale_certificate: res.data.records[0].resale_certificate,
          loading: false,
        });
        this.printPDF();
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  };
  printPDF = () => {
    var iframe = document.getElementById("printingFrame");
    if (!iframe) {
      iframe = document.createElement("iframe");
      iframe.id = "printingFrame";
      iframe.name = "printingFrame";
      iframe.width = "0";
      iframe.height = "0";
      iframe.style = "position: absolute;top: 0;left: 0;width: 0;height: 0;";
      document.body.appendChild(iframe);
    }

    iframe.contentWindow.document.open();
    iframe.contentWindow.document.write("<!DOCTYPE html");
    iframe.contentWindow.document.write("<html><head>");
    iframe.contentWindow.document.write("<title>Order</title>");
    iframe.contentWindow.document.write(
      '<style type="text/css">' +
        "body{font-family:Verdana, Arial;font-size:12px;}" +
        "@media all {.page-break { display: none; }}" +
        "@media print {.page-break { display: block; page-break-before: always; }}" +
        "</style>"
    );
    iframe.contentWindow.document.write("</head><body>");
    iframe.contentWindow.document.write($("#showPdf").html());
    iframe.contentWindow.document.write("</body></html>");
    iframe.contentWindow.document.close();

    window.frames["printingFrame"].focus();
    window.frames["printingFrame"].print();
  };
  onSearch = (e) => {
    e.preventDefault();
    this.getData();
  };
  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  onChangeSelect = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  exportToCSV = () => {
    toast.success("Dowloading...Please Wait!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    var headers = {
      order_id: "Order Id",
      names: "Pharmacy Name",
      email: "Email",
      grandTotal: "GrandTotal",
      date: "Date",
      shipping_price: "Shipping Price",
      shipping_name: "Shipping Name",
    };
    var records = [];
    // add data rows in sheet array
    this.setState({ loading: false });
    let filterData = {
      page_number: 1,
      page_size: 20000,
      sort_order: { column: "date", order: "desc" },
    };

    filterData.search = {
      email: this.props.auth.user.final_user,
    };

    axios
      .post("/api/order-list", filterData)
      .then((res) => {
        // add data rows in sheet array
        for (let i in res.data.records) {
          let each = res.data.records[i];
          each.date = moment(each.date).format("L");
          if (each.grandTotal !== "" && each.grandTotal !== undefined) {
            each.grandTotal = (
              parseFloat(each.grandTotal) + parseFloat(each.shipping_price || 0)
            ).toFixed(2);
          }
          if (each.shipping_price)
            each.shipping_price =
              "$" + parseFloat(each.shipping_price).toFixed(2);
          if (each.shipping_type) {
            each.shipping_name =
              each.shipping_type === "ups"
                ? "USPS"
                : each.shipping_type === "free"
                ? "Free"
                : each.shipping_type === "second_day"
                ? "2nd Business Day"
                : each.shipping_type === "overnight"
                ? "Next Business Day"
                : "";
          }

          records.push(each);
        }
        if (headers) {
          records.unshift(headers);
        }
        let jsonObject = JSON.stringify(records);
        let csv = this.convertToCSV(jsonObject, headers);
        let exportedFilenmae = this.config.filename + ".csv" || "export.csv";
        var blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csv], {
          type: "application/csv;charset=UTF-8",
        });
        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
          let link = document.createElement("a");
          if (link.download !== undefined) {
            let url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  };

  convertToCSV(objArray, headers) {
    const keys = Object.keys(headers);

    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in keys) {
        line +=
          array[i][keys[index]] === undefined
            ? ","
            : `"${array[i][keys[index]]}",`;
        line.trim(",");
      }
      str += line + "\r\n";
    }
    return str;
  }
  render() {
    const {
      order_id,
      name,
      names,
      email,
      grandTotal,
      street,
      city,
      state,
      zip,
      shipping_price = 0,
      shipping_type,
      record = [],
    } = this.state.currentRecord || {};
    let shipping_name =
      shipping_type === "ups"
        ? "USPS"
        : shipping_type === "free"
        ? "Free"
        : shipping_type === "second_day"
        ? "2nd Business Day"
        : shipping_type === "overnight"
        ? "Next Business Day"
        : "";
    const billing_address = street + " " + city + " " + state + " " + zip;
    return (
      <div className="d-flex" id="wrapper">
        {this.state.loading && (
          <div className="loading-wrapper">
            <PulseLoader color="#007bff" loading={true} size={20} />
          </div>
        )}
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <h1 className="mt-2 text-primary">Order List</h1>
            <form noValidate onSubmit={this.onSearch}>
              <div className="form-row">
                <div className="form-group col-md-3">
                  <label htmlFor="order_id">Order ID</label>
                  <input
                    onChange={this.onChange}
                    value={this.state.order_id}
                    id="order_id"
                    type="text"
                    className="form-control"
                  />
                </div>

                <div className="form-group col-md-3">
                  <label htmlFor="names">Pharmacy Name</label>
                  <input
                    onChange={this.onChange}
                    value={this.state.names}
                    id="names"
                    type="text"
                    className="form-control"
                  />
                </div>
                <div>
                  {this.props.auth.user.superadmin && (
                    <>
                      <GroupFilter
                        onChangeSelect={this.onChangeSelect}
                        rx_group_associated={this.state.rx_group_associated}
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="form-group col-md-3">
                <button type="submit" className="btn btn-primary">
                  Search
                </button>
                <button
                  type="reset"
                  onClick={this.resetSearch}
                  className="btn btn-secordry"
                >
                  Reset
                </button>
              </div>
            </form>

            <br></br>
            <br></br>
            <br></br>
            <ReactDatatable
              config={this.config}
              records={this.state.records}
              columns={this.columns}
              total_record={this.state.totalCount}
              dynamic={true}
              loading={true}
              exportToCSV={this.exportToCSV}
            />
          </div>
        </div>
        <ToastContainer />
        <div id="showPdf" style={{ display: "none" }}>
          <div
            bgcolor="#f6f6f6"
            style={{
              color: "#333",
              height: "100%",
              width: "100%",
              fontFamily: "sans-serif",
            }}
            height="100%"
            width="100%"
          >
            <table
              bgcolor="#f6f6f6"
              cellspacing="0"
              style={{ padding: "40px", width: "100%" }}
              width="100%"
            >
              <tbody>
                <tr>
                  <td width="5px" style={{ padding: "5px" }}></td>
                  <td>
                    <table width="100%" cellspacing="0">
                      <tbody>
                        <tr>
                          <td style={{ padding: "5px" }}>
                            <a
                              href="#"
                              style={{ color: "#348eda" }}
                              target="_blank"
                            >
                              <img
                                src="https://www.animalmedexpress.com/images/logo.png"
                                alt="//www.rxconnexion.com//"
                                style={{
                                  height: "80px",
                                  maxWidth: "100%",
                                  width: "250px",
                                }}
                                height="80"
                                width="200"
                              />
                            </a>
                          </td>
                          <td
                            style={{ fontSize: "12px", padding: "5px" }}
                            rowspan="2"
                          ></td>
                          <td
                            style={{
                              fontSize: "12px",
                              padding: "5px",
                              textAlign: "right",
                            }}
                            align="right"
                          >
                            <h2>ORDER FORM</h2>
                            <h3>FEIN # :{this.state.license_no}</h3>

                            <h3>State License # :{this.state.state_license}</h3>
                            <h3>
                              Resale Certificate # :
                              {this.state.resale_certificate}
                            </h3>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "15px", padding: "5px" }}>
                            Phone- 615-538-1424
                            <br />
                            Fax- 615-574-5499
                            <br />
                          </td>

                          <td
                            style={{
                              fontSize: "15px",
                              padding: "5px",
                              textAlign: "right",
                            }}
                            align="right"
                          >
                            ORDER No.:{order_id}
                            {/* <br /> */}
                            <br />
                            DATE: {moment().format("LL")}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td width="5px" style={{ padding: "5px" }}></td>
                </tr>
                <tr>
                  <td width="5px" style={{ padding: "5px" }}></td>
                  <td>
                    <table width="100%" cellspacing="0">
                      <tbody>
                        <tr>
                          <td
                            style={{
                              padding: "5px",
                              width: "50%",
                              fontSize: "15px",
                            }}
                          >
                            BILL TO:
                            <br />
                            {name}
                            <br />
                            {billing_address}
                            <br />
                            {email}
                            <br />
                            {this.props.auth.user.phone}
                            <br />
                          </td>
                          <td
                            style={{
                              padding: "5px",
                              width: "50%",
                              fontSize: "15px",
                            }}
                          >
                            SHIP TO:
                            <br />
                            {names}
                            <br />
                            {billing_address}
                            <br />
                            {email}
                            <br />
                            {this.props.auth.user.phone}
                            <br />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td width="5px" style={{ padding: "5px" }}></td>
                </tr>
                <tr>
                  <td width="5px" style={{ padding: "5px" }}></td>
                  <td>
                    <table width="100%" cellspacing="0">
                      <tbody>
                        <tr>
                          <td
                            style={{
                              padding: "5px",
                              backgroundColor: "#2b3d63 !important",
                              color: "#fff !important",
                              border: "1px solid black",
                            }}
                          >
                            QUANTITY
                          </td>
                          <td
                            style={{
                              padding: "5px",
                              backgroundColor: "#2b3d63 !important",
                              color: "#fff !important",
                              border: "1px solid black",
                            }}
                          >
                            DESCRIPTION
                          </td>
                          <td
                            style={{
                              padding: "5px",
                              backgroundColor: "#2b3d63 !important",
                              color: "#fff !important",
                              border: "1px solid black",
                            }}
                          >
                            UNIT PRICE
                          </td>
                          <td
                            style={{
                              padding: "5px",
                              backgroundColor: "#2b3d63 !important",
                              color: "#fff !important",
                              border: "1px solid black",
                            }}
                          >
                            TOTAL
                          </td>
                        </tr>
                        <tbody>
                          {record !== undefined
                            ? record.map((each) => {
                                return (
                                  <tr key={each} className="form-row">
                                    <td
                                      style={{
                                        padding: "5px",
                                        backgroundColor: "#2b3d63 !important",
                                        color: "#fff !important",
                                        border: "1px solid black",
                                      }}
                                    >
                                      {each.quantity}
                                    </td>
                                    <td
                                      style={{
                                        padding: "5px",
                                        backgroundColor: "#2b3d63 !important",
                                        color: "#fff !important",
                                        border: "1px solid black",
                                      }}
                                    >
                                      {each.name}
                                    </td>
                                    <td
                                      style={{
                                        padding: "5px",
                                        backgroundColor: "#2b3d63 !important",
                                        color: "#fff !important",
                                        border: "1px solid black",
                                      }}
                                    >
                                      ${each.price}
                                    </td>
                                    <td
                                      style={{
                                        padding: "5px",
                                        backgroundColor: "#2b3d63 !important",
                                        color: "#fff !important",
                                        border: "1px solid black",
                                      }}
                                    >
                                      ${each.quantity * each.price}
                                    </td>
                                  </tr>
                                );
                              })
                            : ""}
                        </tbody>
                        <tr>
                          <td
                            style={{
                              padding: "5px",
                              textAlign: "center",
                            }}
                            colspan="2"
                          >
                            <i></i>
                          </td>
                          <td style={{ border: "1px solid", padding: "5px" }}>
                            Shipping({shipping_name})
                          </td>
                          <td style={{ border: "1px solid", padding: "5px" }}>
                            ${parseFloat(shipping_price).toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              padding: "5px",
                              textAlign: "center",
                            }}
                            colspan="2"
                            rowspan="3"
                          >
                            <i></i>
                          </td>
                          <td style={{ border: "1px solid", padding: "5px" }}>
                            GrandTotal
                          </td>
                          <td style={{ border: "1px solid", padding: "5px" }}>
                            ${grandTotal}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td width="5px" style={{ padding: "5px" }}></td>
                </tr>
                <tr>
                  <td width="5px" style={{ padding: "5px" }}></td>
                  <td>
                    <table width="100%" cellspacing="0">
                      <tbody>
                        <tr>
                          <td style={{ padding: "5px", textAlign: "center" }}>
                            <br />
                            THANK YOU FOR YOUR BUSINESS
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td width="5px" style={{ padding: "5px" }}></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  records: state.records,
});

export default connect(mapStateToProps, { logoutUser })(OrderList);
