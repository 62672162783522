import React, { Component } from "react";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";

class GroupFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <>
        {console.log(this.props)}
        {this.props.auth.user.superadmin && this.props.groups.length ? (
          <div className="form-group col-md-3">
            <label>Group</label>
            <select
              className="form-control"
              onChange={this.props.onChangeSelect}
              id="rx_group_associated"
            >
              <option key="group" value="">
                All
              </option>
              {this.props.groups.map((each) => {
                return (
                  <>
                    {each.rx_group !== "superadmin" &&
                      each.email !== undefined && (
                        <option
                          {...(this.props.rx_group_associated ===
                            each.email && { selected: true })}
                          key={each.rx_group}
                          value={each.email}
                        >
                          {each.name}-{each.email}
                        </option>
                      )}
                  </>
                );
              })}
            </select>
          </div>
        ) : null}
        {this.props.auth.user.multistore && this.props.multistoreData.length ? (
          <div className="form-group col-md-3">
            <label>Group</label>
            <select
              className="form-control"
              onChange={this.props.onChangeSelect}
              id="rx_group_associated"
            >
              <option key="group" value="">
                All
              </option>
              {this.props.multistoreData.map((each) => {
                return (
                  <>
                    {each.rx_group !== "superadmin" &&
                      each.email !== undefined && (
                        <option
                          {...(this.props.rx_group_associated ===
                            each.email && { selected: true })}
                          key={each.email}
                          value={each.email}
                        >
                          {each.name}-{each.email}
                        </option>
                      )}
                  </>
                );
              })}
            </select>
          </div>
        ) : null}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  groups: state.groups,
  multistoreData: state.multistoreData,
});

export default connect(mapStateToProps)(GroupFilter);
