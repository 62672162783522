import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import classnames from "classnames";
import ReCAPTCHA from "react-google-recaptcha";
import bona from "../../utils/psga_new.png";
import doctor from "../../utils/doctor.jpg";
import Footer from "../partials/Footer";

var x;
class Login extends Component {
  constructor() {
    super();
    this.state = {
      rx_group: "",
      password: "",
      email: "",
      isFilterOpen: "email",
      errors: {},
    };
  }

  componentDidMount() {
    // window.location.href = "http://analytics.psgarx.com";
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    } else if (
      window.location.hostname !== "localhost" &&
      window.location.hostname !== "54.163.210.25" &&
      window.location.hostname !== "52.7.200.21"
    ) {
      window.location.href = "http://tabula.psgarx.com?logout=true";
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }

    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  handleonChange(value) {
    x = value;
    console.log("Captcha value:", x);
  }
  toggleChange = (value) => {
    this.setState({
      isFilterOpen: value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    // if (x === undefined) { alert("please verify you are not robot") }
    // else {
    const userData = {
      password: this.state.password,
    };
    if (this.state.isFilterOpen === "group") {
      userData.rx_group = this.state.rx_group.toUpperCase();
    } else {
      userData.email = this.state.email;
    }

    this.props.loginUser(userData);
    // }
  };

  render() {
    const { errors } = this.state;
    return (
      <div
        className="nam"
        style={{ backgroundImage: `url(${doctor})` }}
        height={100}
      >
        <div className="container">
          <div className="row">
            {/* <div className="col-md-12" style={{ "textAlign": "center"}}>
                        <img src={bona } height={70} width={230}/>
                      </div> */}
            {/* <img src={bona} width="200"/> */}
            <div className="col-md-4 mx-auto mt-5 mb-5 card shadow-lg">
              <div className="card-body p-1">
                <h2
                  className="text-center text-primary mt-3 "
                  style={{ marginBottom: "20px" }}
                >
                  <img src={bona} height={50} />
                </h2>

                {/* <ul className="nav nav-pills mb-3" id="pills-tab-login" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" id="pills-group-tab" data-toggle="pill" href="#pills-group" role="tab" aria-controls="pills-group" aria-selected="true" onClick={() => this.toggleChange("group")}>Login Using RxGroup</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="pills-email-tab" data-toggle="pill" onClick={() => this.toggleChange("email")} href="#pills-email" role="tab" aria-controls="pills-email" aria-selected="false">Login Using Email</a>
                                </li>
                            </ul> */}
                <div className="tab-content" id="pills-tabContent">
                  {/* <div className="tab-pane fade show active" id="pills-group" role="tabpanel" aria-labelledby="pills-group-tab">
                                    <form noValidate onSubmit={this.onSubmit} className="white">
                                        <label htmlFor="rx_group">Username</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.rx_group}
                                            error={errors.rx_group}
                                            id="rx_group"
                                            type="rx_group"
                                            className={classnames("form-control", {
                                                invalid: errors.rx_group
                                            })}
                                        />
                                        <span className="text-danger">{errors.rx_group}</span>

                                        <br />
                                        <label htmlFor="password">Password</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.password}
                                            error={errors.password}
                                            id="password"
                                            type="password"
                                            className={classnames("form-control", {
                                                invalid: errors.password
                                            })}
                                        />
                                        <span className="text-danger">{errors.password}</span>
                                        <p className="text-center pb-0 mt-2">
                                            <ReCAPTCHA
                                                sitekey="6LcHvlgdAAAAALnnGTw7Mexefqz50E0Ob0qvNB3L"
                                                onChange={this.handleonChange}
                                            />
                                            <button
                                                type="submit"
                                                className="btn btn-large btn-primary mt-2 px-5">
                                                Login
                                            </button>
                                            <br></br>
                                            <br></br>
                                            <Link to="/forgot">Forgot Password</Link>
                                            <h6 className="pt-3">OR</h6>

                                            <Link to="/register"><button type="submit"
                                                className="btn btn-large btn-primary mt-2 px-5">Sign Up</button></Link>
                                        </p>


                                    </form>
                                </div> */}
                  <div
                    className="tab-pane fade show active"
                    id="pills-email"
                    role="tabpanel"
                    aria-labelledby="pills-email-tab"
                  >
                    <form noValidate onSubmit={this.onSubmit} className="white">
                      <label htmlFor="email">Email </label>
                      <input
                        onChange={this.onChange}
                        value={this.state.email}
                        error={errors.email}
                        id="email"
                        type="email"
                        className={classnames("form-control", {
                          invalid: errors.email,
                        })}
                      />
                      <span className="text-danger">{errors.email}</span>
                      <br />
                      <label htmlFor="password">Password</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.password}
                        error={errors.password}
                        id="password"
                        type="password"
                        className={classnames("form-control", {
                          invalid: errors.password,
                        })}
                      />
                      <span className="text-danger">{errors.password}</span>
                      <p className="text-center pb-0 mt-2">
                        <ReCAPTCHA
                          sitekey="6LcHvlgdAAAAALnnGTw7Mexefqz50E0Ob0qvNB3L"
                          onChange={this.handleonChange}
                        />
                        <button
                          type="submit"
                          className="btn btn-large btn-primary mt-2 px-5"
                        >
                          Login
                        </button>
                        <br></br>
                        <br></br>
                        <Link to="/forgot">Forgot Password</Link>
                        <h6 className="pt-3">OR</h6>

                        <Link to="/register">
                          <button
                            type="submit"
                            className="btn btn-large btn-primary mt-2 px-5"
                          >
                            Sign Up
                          </button>
                        </Link>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});
export default connect(mapStateToProps, { loginUser })(Login);
