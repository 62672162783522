import React, { Component, Fragment } from "react";
import ReactDatatable from "../../utils/ReactDatatable";
import { connect } from "react-redux";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import moment from "moment";
import { faPlus, faBackward } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { logoutUser } from "../../actions/authActions";
import { Link } from "react-router-dom";
import { Collapse } from "react-collapse";
import AnimalDrugAdd from "../partials/AnimalDrugAdd";

class AnimalDrug extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: "link",
        text: "View",
        className: "link",
        align: "left",
        sortable: false,
        view: true,
      },
      {
        key: "proprietary_name",
        text: "Proprietary Name",
        className: "proprietary_name",
        align: "left",
        sortable: true,
      },
      {
        key: "ndc",
        text: "NDC",
        className: "ndc",
        align: "left",
        sortable: true,
      },
      {
        key: "non_proprietary_name",
        text: "Non-Proprietary Name",
        className: "non_proprietary_name",
        align: "left",
        sortable: true,
      },
      {
        key: "labeler",
        text: "Manufacturer Name",
        className: "nalabelerme",
        align: "left",
        sortable: true,
      },
      {
        key: "product_type",
        text: "Product Type",
        className: "product_type",
        align: "left",
        sortable: true,
      },
    ];

    this.config = {
      page_size: 20,
      length_menu: [20, 50, 100],
      filename: "Drug List",
      no_data_text: "No record found!",
      button: {
        excel: false,
        print: false,
        csv: true,
      },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: false,
      show_pagination: true,
      show_info: true,
    };

    this.state = {
      records: [],
      totalCount: 0,
      filterData: {
        page_number: 1,
        page_size: 20,
        sort_order: { column: "ndc", order: "asc" },
      },
      currentRecord: {},
      proprietary_name: undefined,
      ndc: undefined,
      non_proprietary_name: undefined,
      labeler: undefined,
      product_type: undefined,
      loading: false,
      isFilterOpen: true,
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    this.setState({ loading: true });
    let filterData = this.state.filterData;
    filterData.search = {
      ...(this.state.ndc !== "" && { ndc: this.state.ndc }),
      ...(this.state.proprietary_name !== "" && {
        proprietary_name: this.state.proprietary_name,
      }),
      ...(this.state.non_proprietary_name !== "" && {
        non_proprietary_name: this.state.non_proprietary_name,
      }),
      ...(this.state.labeler !== "" && { labeler: this.state.labeler }),
      ...(this.state.product_type !== "" && {
        product_type: this.state.product_type,
      }),
    };
    axios
      .post("/api/animal-drug", filterData)
      .then((res) => {
        let result = [];
        for (let i = 0; i < res.data.records.length; i++) {
          let each = res.data.records[i];
          if (each.ndc !== "") {
            result.push(each);
          }
        }
        this.setState({
          records: result,
          totalCount: res.data.count,
          loading: false,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  }

  filter = (filterData) => {
    this.setState({ filterData }, () => this.getData());
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSearch = (e) => {
    e.preventDefault();
    this.getData();
  };

  resetSearch = () => {
    this.setState(
      {
        proprietary_name: undefined,
        ndc: undefined,
        non_proprietary_name: undefined,
        labeler: undefined,
        product_type: undefined,
        filterData: {
          page_number: 1,
          page_size: 20,
          sort_order: { column: "dea", order: "asc" },
        },
      },
      () => this.getData()
    );
  };

  toggleChange = () => {
    this.setState({
      isFilterOpen: !this.state.isFilterOpen,
    });
  };

  onChangeSelect = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  exportToCSV = () => {
    toast.success("Dowloading...Please Wait!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    var headers = {
      ndc: "NDC",
      proprietary_name: "Proprietary Name",
      non_proprietary_name: "Non-Proprietary Name",
      labeler: "Manufacturer",
      product_type: "Product Type",
      link: "Link",
    };
    let filterData = {
      page_number: 1,
      page_size: 20000,
      sort_order: { column: "_id", order: "asc" },
    };
    filterData.search = {
      ...(this.state.ndc !== "" && { ndc: this.state.ndc }),
      ...(this.state.proprietary_name !== "" && {
        proprietary_name: this.state.proprietary_name,
      }),
      ...(this.state.non_proprietary_name !== "" && {
        non_proprietary_name: this.state.non_proprietary_name,
      }),
      ...(this.state.labeler !== "" && { labeler: this.state.labeler }),
      ...(this.state.product_type !== "" && {
        product_type: this.state.product_type,
      }),
    };
    axios
      .post("/api/animal-drug", filterData)
      .then((res) => {
        var records = [];
        // add data rows in sheet array
        this.setState({ loading: false });
        for (let i in res.data.records) {
          let record = res.data.records[i];

          record.name = '"' + record.name + '"';
          record.address = '"' + record.address + '"';
          record.additional_info = '"' + record.additional_info + '"';
          record.dba = '"' + record.dba + '"';
          // record.ndc_label = '"' + record.ndc_label + '"'
          if (record.expires !== "") {
            record.expires = moment(record.expires).format("L");
          }
          // if (record.relation_end_date !== '') {
          //     record.relation_end_date = moment(record.relation_end_date).format('L');
          // }
          // if (record.start_date !== '') {
          //     record.start_date = moment(record.start_date).format('L');
          // }
          // if (record.end_date !== '') {
          //     record.end_date = moment(record.end_date).format('L');
          // }
          records.push(record);
        }
        if (headers) {
          records.unshift(headers);
        }
        // Convert Object to JSON
        // var csvString = 'ı,ü,ü,ğ,ş,#Hashtag,ä,ö,Â';
        // var universalBOM = "\uFEFF";
        let jsonObject = JSON.stringify(records);
        let csv = this.convertToCSV(jsonObject, headers);
        let exportedFilenmae = this.config.filename + ".csv" || "export.csv";
        // let blob = new Blob([csv], { type: 'text/csv;charset=utf-8' +  encodeURIComponent(universalBOM+csvString) });
        var blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csv], {
          type: "application/csv;charset=UTF-8",
        });
        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
          let link = document.createElement("a");
          if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            let url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  };

  convertToCSV(objArray, headers) {
    const keys = Object.keys(headers);
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in keys) {
        line +=
          array[i][keys[index]] === undefined
            ? ","
            : array[i][keys[index]] + ",";
        line.trim(",");
      }
      str += line + "\r\n";
    }
    return str;
  }

  render() {
    return (
      <div className="d-flex" id="wrapper">
        {this.state.loading && (
          <div className="loading-wrapper">
            <PulseLoader color="#007bff" loading={true} size={20} />
          </div>
        )}
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <h1 className="mt-2 text-primary">Animal Drug List</h1>

            <br></br>
            <br></br>
            <div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={this.state.isFilterOpen}
                  onClick={this.toggleChange}
                  id="isFilterOpen"
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Show Filter
                </label>
              </div>
              <Collapse isOpened={this.state.isFilterOpen}>
                <form noValidate onSubmit={this.onSearch}>
                  <div className="form-row">
                    <div className="form-group col-md-3">
                      <label htmlFor="ndc">NDC</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.ndc}
                        id="ndc"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="proprietary_name">Proprietary Name</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.proprietary_name}
                        id="proprietary_name"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="non_proprietary_name">
                        Non-Proprietary Name
                      </label>
                      <input
                        onChange={this.onChange}
                        value={this.state.non_proprietary_name}
                        id="non_proprietary_name"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="labeler">Manufacturer</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.labeler}
                        id="labeler"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="product_type">Product Type</label>
                      <select
                        onChange={this.onChangeSelect}
                        id="product_type"
                        className="form-control"
                      >
                        <option value="">Please Select</option>
                        <option value="BULK INGREDIENT">BULK INGREDIENT</option>
                        <option value="OTC ANIMAL DRUG">OTC ANIMAL DRUG</option>
                        <option value="OTC TYPE A MEDICATED ARTICLE ANIMAL DRUG">
                          OTC TYPE A MEDICATED ARTICLE ANIMAL DRUG
                        </option>
                        <option value="PRESCRIPTION ANIMAL DRUG">
                          PRESCRIPTION ANIMAL DRUG
                        </option>
                        <option value="RECOMBINANT DEOXYRIBONUCLEIC ACID CONSTRUCT">
                          RECOMBINANT DEOXYRIBONUCLEIC ACID CONSTRUCT
                        </option>
                        <option value="VFD TYPE A MEDICATED ARTICLE ANIMAL DRUG">
                          VFD TYPE A MEDICATED ARTICLE ANIMAL DRUG
                        </option>
                      </select>
                    </div>
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Filter
                  </button>
                  <button
                    type="reset"
                    onClick={this.resetSearch}
                    className="btn btn-secordry"
                  >
                    Reset
                  </button>
                </form>
              </Collapse>
            </div>
            <br></br>
            <ReactDatatable
              config={this.config}
              records={this.state.records}
              columns={this.columns}
              onChange={this.filter}
              dynamic={true}
              total_record={this.state.totalCount}
              loading={true}
              exportToCSV={this.exportToCSV}
            />
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  records: state.records,
});

export default connect(mapStateToProps, { logoutUser })(AnimalDrug);
