import React from "react";
import classnames from "classnames";
import axios from "axios";
import isEmpty from "is-empty";
import { ToastContainer, toast } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import { faPlus, faDownload,faCog } from "@fortawesome/free-solid-svg-icons";

class UserSettings extends React.Component {
  constructor() {
    super();
    this.state = {
      brand_percent: "80",
      generic_percent: "15",
      otc_percent: "5",
      _id: "",
      errors: {},
      records: [],
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    this.setState({ loading: true });
    let filterData = {
      page_number: 1,
      page_size: 20,
      sort_order: { column: "rx_group", order: "desc" },
    };
    filterData.search = {
      rx_group: this.props.auth.user.rx_group,
    };
    axios
      .post("/api/user-data", filterData)
      .then((res) => {
        this.setState({
          brand_percent: res.data.records[0].brand_percent,
          generic_percent: res.data.records[0].generic_percent,
          otc_percent: res.data.records[0].otc_percent,
          _id: res.data.records[0]._id,
          loading: false,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSave = (e) => {
    e.preventDefault();
    let { errors } = this.state;
    if (this.state.brand_percent === "") {
      errors.brand_percent = "This field is required";
    } else if (isNaN(this.state.brand_percent)) {
      errors.brand_percent = "This field should be number";
    } else {
      delete errors.brand_percent;
    }
    if (this.state.generic_percent === "") {
      errors.generic_percent = "This field is required";
    } else if (isNaN(this.state.generic_percent)) {
      errors.generic_percent = "This field should be number";
    } else {
      delete errors.generic_percent;
    }
    if (this.state.otc_percent === "") {
      errors.otc_percent = "This field is required";
    } else if (isNaN(this.state.otc_percent)) {
      errors.otc_percent = "This field should be number";
    } else {
      delete errors.otc_percent;
    }
    if (isEmpty(errors)) {
      this.setState({ errors });
      const newWaiver = {
        id: this.state._id,
        brand_percent: this.state.brand_percent,
        generic_percent: this.state.generic_percent,
        otc_percent: this.state.otc_percent,
      };
      axios
        .post("/api/setting-update", newWaiver)
        .then((res) => {
          this.getData();
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        })
        .catch((err) => console.log(err));
    } else {
      this.setState({ errors });
    }
  };

  render() {
    const { errors } = this.state;
    return (
      <div className="d-flex" id="wrapper">
        {this.state.loading && (
          <div className="loading-wrapper">
            <PulseLoader color="#007bff" loading={true} size={20} />
          </div>
        )}
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <h1 className="mt-2 text-primary">Settings</h1>
            <p className="help-text font-weight-bold h6">
              Please enter your percentage of Brand, Generic and OTC from your
              wholesalers record.
            </p>
            <form noValidate onSubmit={this.onSave} id="add-drug">
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="v">Brand Percent</label>
                </div>
                <div className="col-md-4">
                  <input
                    onChange={this.onChange}
                    value={this.state.brand_percent}
                    id="brand_percent"
                    type="text"
                    error={errors.brand_percent}
                    className={classnames("form-control", {
                      invalid: errors.brand_percent,
                    })}
                  />
                  <span className="text-danger">{errors.brand_percent}</span>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="generic_percent">Generic Percent</label>
                </div>
                <div className="col-md-4">
                  <input
                    onChange={this.onChange}
                    value={this.state.generic_percent}
                    id="generic_percent"
                    type="text"
                    error={errors.generic_percent}
                    className={classnames("form-control", {
                      invalid: errors.generic_percent,
                    })}
                  />
                  <span className="text-danger">{errors.generic_percent}</span>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="otc_percent">OTC Percent</label>
                </div>
                <div className="col-md-4">
                  <input
                    onChange={this.onChange}
                    value={this.state.otc_percent}
                    id="otc_percent"
                    type="text"
                    error={errors.otc_percent}
                    className={classnames("form-control", {
                      invalid: errors.otc_percent,
                    })}
                  />
                  <span className="text-danger">{errors.otc_percent}</span>
                </div>
              </div>
              <Link to="/business-tool">
                <button className="btn btn-secordry">Back</button>
              </Link>

              <button type="submit" className="btn btn-primary">
                Save
              </button>
            </form>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  pharmacy: state.pharmacy,
  groups: state.groups,
});

export default connect(mapStateToProps, null)(UserSettings);
