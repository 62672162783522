import React from 'react'
import classnames from "classnames";
import axios from "axios";
import { connect } from "react-redux";
import $ from 'jquery';
import isEmpty from 'is-empty';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment"
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-toastify/dist/ReactToastify.css';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import tabula from "../../utils/TabulaRx.png";
import { refreshUser } from "./../../actions/authActions";
class Tabula extends React.Component {

    constructor() {
        super();
        this.state = {
        };
    }

    startTrail = () => {
        const newUser = {
            id: this.props.auth.user.id,
            tabula_trial: true,
            tabulaRx: true
        };
        axios.post("/api/user-update", newUser).then(res => {
            this.props.refreshUser({ id: this.props.auth.user.id }, "tabula")
            $('#tabula-modal').modal('hide');

        }).catch(err =>
            console.log(err)
        )
    }

    render() {
        const { errors } = this.state;
        const { errs } = this.state;

        return (
            <div>
                <div className="modal fade" id="tabula-modal" data-reset="true">
                    <div className="modal-dialog modal-xl">

                        <div className="modal-content">
                            <div className="modal-header" style={{display:"block"}}>
                                <div style={{float:"left"}}>
                                    <img width="200px" src={tabula} />
                                </div>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <br></br>
                                <br></br>
                                <h3 className="modal-title" style={{clear:"both"}}>The Smart Way to Increase Revenue, Reduce Overhead and Improve Cash Flow</h3>
                                
                            </div>
                            <div className="modal-body center">
                                <video width="90%" height="90%" controls>
                                    <source src="https://rxreachanalytis.s3.amazonaws.com/TabulaRx+Quick+Tour.mp4" type="video/mp4" />
                                    <source src="https://rxreachanalytis.s3.amazonaws.com/TabulaRx+Quick+Tour.mp4" type="video/ogg" />
                                </video>
                                <br></br>
                            </div>
                            <div className="modal-footer">
                                <button
                                    onClick={this.startTrail}
                                    type="button"
                                    className="btn btn-success btn-lg">
                                    Start your free 30 days Trial
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(
    mapStateToProps,
    {refreshUser}
)(Tabula);
