import React, { Component } from "react";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import { NavLink } from "react-router-dom";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bona from "../../utils/psga.png";
import {
  faUserFriends,
  faBars,
  faEnvelope,
  faPrescriptionBottle,
  faClinicMedical,
  faChartPie,
  faHospital,
  faPodcast,
  faFileAudio,
  faReceipt,
  faList,
  faShoppingBasket,
} from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";
const css = `
    .offset-2 {
        margin-left: 60px;
    }`;
// const n = `
// {
//     background-color: yellow;
//   }`
class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
    };
  }

  // shi = () => {
  //     console.log("kdkkdkd")
  // }
  onLogoutClick = (e) => {
    e.preventDefault();
    this.props.logoutUser(this.props.history);
  };

  handleToggleSidebar = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  collapseOther = (e, type) => {
    if (type === "parent") {
      $("li.pro-menu-item.pro-sub-menu").removeClass("open");
      $(".react-slidedown.pro-inner-list-item").addClass("closed");
    } else {
      $("li.pro-menu-item.pro-sub-menu").removeClass("open");
      $(".react-slidedown.pro-inner-list-item").addClass("closed");
      e.target.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.classList.add(
        "open"
      );
      e.target.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.classList.remove(
        "closed"
      );
    }
  };

  onAudioClick = (e) => {
    this.collapseOther(e, "parent");
    window.location.href = "/audio";
  };

  render() {
    //const { user } = this.props.auth;
    return (
      <ProSidebar collapsed={this.state.collapsed}>
        {this.state.collapsed && <style>{css}</style>}
        <SidebarHeader>
          {!this.state.collapsed && (
            <NavLink activeClassName="is-active" to="/">
              <img src={bona} alt="logo" />
            </NavLink>
          )}
          <FontAwesomeIcon icon={faBars} onClick={this.handleToggleSidebar} />
        </SidebarHeader>
        <SidebarContent>
          <Menu iconShape="square">
            {!this.props.auth.user.superadmin && (
              <MenuItem
                icon={<FontAwesomeIcon icon={faClinicMedical} />}
                onClick={(e) => this.collapseOther(e, "parent")}
              >
                <NavLink
                  activeClassName="is-active"
                  to="/dashboard"
                  className="list-group-item list-group-item-action"
                >
                  Place Order
                </NavLink>
              </MenuItem>
            )}
            <MenuItem
              icon={<FontAwesomeIcon icon={faList} />}
              onClick={(e) => this.collapseOther(e, "")}
            >
              <NavLink
                activeClassName="is-active"
                to="/order-list"
                className="list-group-item list-group-item-action"
              >
                Order List
              </NavLink>
            </MenuItem>
            <MenuItem
              icon={<FontAwesomeIcon icon={faList} />}
              onClick={(e) => this.collapseOther(e, "")}
            >
              <NavLink
                activeClassName="is-active"
                to="/animal-med"
                className="list-group-item list-group-item-action"
              >
                Animal Drug
              </NavLink>
            </MenuItem>
          </Menu>
        </SidebarContent>
        <SidebarFooter style={{ textAlign: "center" }}></SidebarFooter>
      </ProSidebar>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(Sidebar);
