import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-pro-sidebar/dist/css/styles.css";
import { faSignOutAlt, faBackward } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import moment from "moment";
class Navbar extends Component {
  onLogoutClick = (e) => {
    e.preventDefault();
    this.props.logoutUser(this.props.history);
  };

  render() {
    const { user } = this.props.auth;
    return (
      <div className="container-fluid p-0">
        <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
          {/* <a className="navbar-brand" href="/">BonaRx Analytics</a> */}
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            {!["/landing"].includes(window.location.pathname) &&
              this.props.auth.user.final_user !==
                "david.bigoness@animalmedexpress.com" &&
              this.props.auth.user.final_user !==
                "cortney@animalmedexpress.com" && (
                <button type="button" class="btn btn-warning text-dark">
                  <a
                    className="text-dark"
                    href="https://tabula.psgarx.com/landing"
                  >
                    <FontAwesomeIcon icon={faBackward} />
                    {"  "}
                    <b>Go to Main Menu</b>
                  </a>
                </button>
              )}

            {!this.props.auth.user.superadmin && (
              <ul className="navbar-nav ml-auto">
                <li className="nav-item active">
                  <a
                    className="nav-link"
                    href="/support"
                    // onClick={this.onLogoutClick}
                  >
                    Support
                  </a>
                </li>
              </ul>
            )}
            <ul className="navbar-nav ml">
              <li className="nav-item active">
                <a className="nav-link" href="#" onClick={this.onLogoutClick}>
                  Logout ({user.name}) <FontAwesomeIcon icon={faSignOutAlt} />{" "}
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(Navbar);
